<template>
  <el-main>
    <div class="search">
      <div class="left">
        <span>设备数量</span>
        <span>{{ total_number }}个</span>
      </div>
      <div class="right">
        <el-input v-model="equipment_no" placeholder="请输入搜索..."></el-input>
        <el-button type="primary" @click="getList">搜索</el-button>
        <el-button @click="showAdd = !0">添加</el-button>
        <!-- <el-button @click="output">导出</el-button>
        <el-button>导入</el-button> -->
      </div>
    </div>
    <el-table :data="list" row-key="id"
      ><el-table-column
        prop="equipment_no"
        label="平台设备编号"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="bracelet_number"
        label="硬件编码"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="bracelet_number"
        label="设备是否重新编码"
        align="center"
      >
        <template v-slot="{ row }">
          {{ row.is_default ? "已经编码" : "还未编码" }}
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="创建日期" align="center">
        <template v-slot="{ row }">
          {{ getDateformat(row.create_time) }}
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-size="rows"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total_number"
    >
    </el-pagination>
    <el-dialog title="添加设备" :visible.sync="showAdd" width="600px">
      <el-form label-width="140px">
        <el-form-item label="地址：">
          <el-input v-model="addForm.address"></el-input>
        </el-form-item>
        <el-form-item label="省市区：" class="city">
          <div class="box">
            <el-select
              v-model="province_name"
              placeholder="请选择"
              @change="(val) => sonArea(0, val)"
            >
              <el-option
                v-for="(item, index) in area"
                :key="index"
                :label="item.name"
                :value="item.name"
              ></el-option>
            </el-select>
            <el-select
              v-model="city_name"
              placeholder="请选择"
              @change="(val) => sonArea(1, val)"
            >
              <el-option
                v-for="(item, index) in cityArr"
                :key="index"
                :label="item.name"
                :value="item.name"
              ></el-option>
            </el-select>
            <el-select
              v-model="area_name"
              placeholder="请选择"
              @change="(val) => sonArea(2, val)"
            >
              <el-option
                v-for="(item, index) in areaArr"
                :key="index"
                :label="item.name"
                :value="item.name"
              ></el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item label="硬件编码：">
          <el-input v-model="addForm.bracelet_number"></el-input>
        </el-form-item>
        <el-form-item label="平台设备编号：">
          <el-input v-model="addForm.equipment_no"></el-input>
        </el-form-item>
        <el-form-item label="设备是否重新编码：">
          <el-radio-group v-model="addForm.is_default">
            <el-radio :label="1">已经编码</el-radio>
            <el-radio :label="0">还未编码</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showAdd = !1">取 消</el-button>
        <el-button type="primary" @click="comfirmAdd">确 定</el-button>
      </span>
    </el-dialog>
  </el-main>
</template>

<script>
import { getDateformat } from "@/utils/getDate";
export default {
  data() {
    return {
      page: 1,
      rows: 10,
      equipment_no: "",
      list: [],
      total_number: 0,
      showAdd: !1,
      area_name: "",
      province_name: "",
      city_name: "",
      area: [],
      cityArr: [],
      areaArr: [],
      addForm: {
        bracelet_number: "",
        equipment_no: "",
        is_default: 1,
        area_id: "",
        address: "",
      },
    };
  },
  created() {
    this.getList();
    this.getArea();
  },
  methods: {
    getList() {
      let data = {
        page: this.page,
        rows: this.rows,
      };
      if (this.keywords) data.equipment_no = this.equipment_no;
      this.$axios.post(this.$api.shipper.list, data).then((res) => {
        if (res.code == 0) {
          let list = res.result.list;
          this.list = list;
          this.total_number = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    comfirmAdd() {
      this.$axios.post(this.$api.shipper.add, this.addForm).then((res) => {
        if (res.code == 0) {
          this.$message.success("添加成功");
          this.showAdd = !1;
          this.getList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 获取省市区信息
    getArea() {
      let that = this;
      this.$axios
        .get(this.$api.area, {
          tree: 1,
          level: 2,
        })
        .then((res) => {
          if (res.code == 0) {
            that.area = res.result.list;
          }
        });
    },
    // 三级联动选择地区
    sonArea(status, val) {
      if (status == 0) {
        let index = this.area.find((item) => item.name == val);
        this.cityArr = index._child;
        this.province_name = index.name;
      } else if (status == 1) {
        let index = this.cityArr.find((item) => item.name == val);
        this.areaArr = index._child;
        this.city_name = index.name;
      } else if (status == 2) {
        let index = this.areaArr.find((item) => item.name == val);
        this.area_name = index.name;
        this.addForm.area_id = index.id;
      }
    },
    handleSizeChange(val) {
      this.rows = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },
    getDateformat(val) {
      return getDateformat(val);
    },
  },
};
</script>
<style lang="less" scoped>
.el-main {
  color: #333;
  background: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: left;
  .search {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    .left {
      font-weight: bold;
      font-size: 18px;
      span:nth-child(2) {
        margin-left: 4px;
        color: #1e92ff;
      }
    }
    .right {
      .el-input {
        width: 270px;
        margin-right: 20px;
        /deep/ .el-input__inner {
          border-radius: 19px;
          margin-right: 20px;
        }
      }

      .el-button {
        border-radius: 19px;
      }
      .el-button--default {
        border-color: #409eff;
      }
    }
  }
  .el-table {
    border-top: 1px solid #ebeef5;
    border-left: 1px solid #ebeef5;
    border-right: 1px solid #ebeef5;
    margin-bottom: 10px;
  }
  .city .box {
    display: flex;
    justify-content: space-between;
    .el-select {
      flex: 1;
      margin-right: 10px;
      &:last-child {
        margin: 0;
      }
    }
  }
}
</style>
